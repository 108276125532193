<template>
  <div class="main_container">
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Enregistrement budgétaire
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row my-5">
      <form class="col-md-10 mx-auto">
        <div class="form-group row">
          <div class="col-md-6">
            <label class="req">Base:</label>
            <select class="form-control"
                    v-model="form.base">
              <option value="">Choisissez une base</option>
              <option value="SIEGE">SIEGE</option>
              <option value="PEAGE">PEAGE</option>
              <option value="PESAGE">PESAGE</option>
              <option value="AIRE_STATIONNEMENT">AIRE DE STATIONNEMENT</option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="req">Compte comptable:</label>
            <select class="form-control"
                    v-model="form.compte"
                    v-if="planComptes.donnees">
              <option value="">Choisissez un numéro de compte comptable</option>
              <option v-for="(plan, plkey) in planComptes.donnees"
                      :key="plkey"
                      :value="plan.compte">{{plan.compte}} - {{plan.description}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label for="">Libellé opération:</label>
            <input type="text"
                   class="form-control"
                   v-model="form.libelle">
          </div>
          <div class="col-md-6">
            <label>Banque:</label>
            <select class="form-control"
                    v-model="form.compte_bancaire"
                    v-if="banks.length>0">
              <option value="">Choisissez une banque</option>
              <optgroup v-for="(banque,bakey) in banks"
                        :key="bakey"
                        :label="banque.denomination + '(' + banque.sigle+')'">
                <option v-for="(compte, cokey) in banque.compte_bancaires"
                        :key="cokey"
                        :value="compte.id">{{compte.numero}}</option>
              </optgroup>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label for="">Budget annuel:</label>
            <input type="text"
                   class="form-control"
                   v-model="budget_annuel"
                   @input="addSpace('budget_annuel')">
          </div>
          <div class="col-md-6">
            <label for="">Découpage mensuel du budget:</label>
            <select class="form-control"
                    v-model="opt"
                    @change="linearMonth()">
              <option value="">Choisissez une option</option>
              <option value="1">Linéaire</option>
              <option value="2">Manuelle</option>
            </select>
          </div>
        </div>
        <div class="form-group row"
             v-if="opt=='2'">
          <div class="col-md-1"
               v-for="(budget, budgkey) in budget_mensuel"
               :key="budgkey">
            <label for="">{{budget.mois}}</label>
            <input type="number" 
                   class="form-control"
                   min="0"
                   max="100"
                   v-model="budget.valeur"
                   @change="verifMois()">
          </div>
          <div class="col-md-1">
            <label for="">Pourcentage (%)</label>
            <input type="number" 
                   class="form-control"
                   min="0"
                   max="100"
                   v-model="pourcentage">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label class="req">Année budgétaire:</label>
            <input type="text"
                   class="form-control"
                   v-model="form.date">
          </div>
          
        </div>
        <div class="form-group row">
          <div class="col-md-6">

            <label class="req">Type</label>
            <select class="form-control"
                    name=""
                    id=""
                    v-model="form.type"
                    @change="getSousType()">
              <option value="">Choisissez un type</option>
              <option value="REVENUS">RESSOURCES</option>
              <option value="CHARGES">EMPLOIS</option>
              <option value="LEVEE_FONDS">LEVEE DE FONDS</option>
            </select>
            <span v-if="form.errors().has('type')"
                  v-text="form.errors().get('type')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-6">
            <label class="req">Sous-type</label>
            <select v-if="form.type == 'LEVEE_FONDS'"
                    class="form-control"
                    name=""
                    id=""
                    v-model="form.sous_type">
              <option value="">Choisissez un sous-type</option>
              <option value="LEVEE_FONDS">LEVEE DE FONDS</option>
            </select>
            <select v-else-if="sousTypeList.length >0"
                    class="form-control"
                    name=""
                    id=""
                    v-model="form.sous_type">
              <option value="">Choisissez un sous-type</option>
              <option v-for="(soustype,stkey) in sousTypeList"
                      :key="stkey"
                      :value="soustype.sous_type">{{soustype.sous_type}}</option>
            </select>
            <select v-else
                    class="form-control"
                    name=""
                    id=""
                    v-model="form.sous_type">
              <option value="">Choisissez un sous-type</option>
            </select>
            <span v-if="form.errors().has('sous_type')"
                  v-text="form.errors().get('sous_type')"
                  class="errorMsg"> </span>
          </div>
        </div>
        <div class="form-group row">
          <!-- <div class="col-md-6">
            <label for="">Référence</label>
            <input class="form-control"
                   type="text"
                   name=""
                   id=""
                   v-model="form.reference" />
            <span v-if="form.errors().has('reference')"
                  v-text="form.errors().get('reference')"
                  class="errorMsg"> </span>
          </div> -->
          <div class="col-md-6">
            <label for="">Commentaires:</label>
            <input class="form-control"
                   type="text"
                   name=""
                   id=""
                   v-model="form.commentaire" />
            <span v-if="form.errors().has('commentaires')"
                  v-text="form.errors().get('commentaires')"
                  class="errorMsg"> </span>
          </div>
        </div>
        
        <div class="button_form">
          <button type="button"
                  class="btn btn-f-blue enter_btn"
                  @click='submit'>
            <i class="flaticon-floppy-disk"></i> Enregistrer
          </button>
          <button type="reset"
                  class="btn btn-f-blue cancle_btn ml-4">
            <i class="flaticon-cancel"></i> Annuler
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
form.mx-auto input[type="text"], 
form.mx-auto input[type="date"], 
form.mx-auto input[type="number"], 
form.mx-auto input[type="file"], 
form.mx-auto textarea,
form.mx-auto select option,
form.mx-auto select.form-control,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}
form.mx-auto input[type="text"], 
form.mx-auto input[type="date"], 
form.mx-auto input[type="number"], 
form.mx-auto input[type="file"], 
form.mx-auto textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import form from "vuejs-form"

export default ({
  name:"AddTransaction",
  components:{
    Notif
  },
  data:()=>({
    form:form({
      "base": "",
      "compte": "",
      "compte_bancaire": "",
      "debit" : "",
      "credit": "",
      "type" : "",
      "sous_type": "",
      "date" :"",
      "libelle":"",
      "commentaire":"",
      "reference":"",
      "tranche_levee_fond":"",
      "levee_fond":"",
      "zone_gare":"",
      "gare_peage":"",
      "classe_vehicule":"",
      "gare_pesage":"",
      "departement":"CONTROLE_GESTION",
      "budget_annuel":"",
      "realisation":"",
      "janvier":"",
      "fevrier":"",
      "mars":"",
      "avril":"",
      "mai":"",
      "juin":"",
      "juillet":"",
      "aout":"",
      "septembre":"",
      "octobre":"",
      "novembre":"",
      "decembre":"",
    }).rules({
      "base": "required",
      // "compte": "required",
      // "compte_bancaire": "required",
      // "debit" : "required",
      // "credit": "required",
      "type" : "required",
      "sous_type": "required",
      "date" :"required",
    }).messages({
      "base": "Veuillez renseigner le champ base",
      // "compte": "Veuillez renseigner le champ compte",
      // "compte_bancaire": "Veuillez renseigner le champ compte bancaire",
      "debit" : "Veuillez renseigner le champ débit",
      "credit": "Veuillez renseigner le champ crédit",
      "type" : "Veuillez renseigner le champ type",
      "sous_type": "Veuillez renseigner le champ sous_type",
      "date" :"Veuillez renseigner le champ date",
    }),
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    banks:[],
    sousTypeList:"",
    opt:"",
    debit:"",
    credit:"",
    pourcentage:"",
    budget_annuel:"",
    budget_mensuel:Array(
      {mois:"jan",libelle:"janvier",valeur:""},
      {mois:"fev",libelle:"fevrier",valeur:""},
      {mois:"mars",libelle:"mars",valeur:""},
      {mois:"avr",libelle:"avril",valeur:""},
      {mois:"mai",libelle:"mai",valeur:""},
      {mois:"juin",libelle:"juin",valeur:""},
      {mois:"juil",libelle:"juillet",valeur:""},
      {mois:"aout",libelle:"aout",valeur:""},
      {mois:"sept",libelle:"septembre",valeur:""},
      {mois:"oct",libelle:"octobre",valeur:""},
      {mois:"nov",libelle:"novembre",valeur:""},
      {mois:"dec",libelle:"decembre",valeur:""}
    )
  }),
  watch:{
    banques(){
      if (this.banques.donnees) {
        this.banks=[]
        this.banques.donnees.forEach(banque => {
          if (banque.compte_bancaires.length>0) {
            this.banks.push(banque)
          }
        })
        // console.log(this.banks)
      }
    },
    msgSuccessComptabiliteTransaction(){
      if (this.msgSuccessComptabiliteTransaction) {
        this.notif.message = "transaction enregistrée avec succès"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgSuccessComptabiliteTransaction("")
            this.$router.push({ name: "ControleDeGestion" })
          }.bind(this),
          2000
        )
      }
    },
    msgFailComptabiliteTransaction(){
      if (this.msgFailComptabiliteTransaction) {
        this.notif.message = this.msgFailComptabiliteTransaction
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailComptabiliteTransaction("")
          }.bind(this),
          2000
        )
      }
    },
  },
  created(){
    this.leveeFonds()
    this.loadBanques()
    this.loadTypeTransactions()
    this.getListPlanComptes()
  },
  computed:{
    ...mapGetters(["msgSuccessComptabiliteTransaction","msgFailComptabiliteTransaction","leveefond","banques","typeTransactions","planComptes"])
  },
  methods:{
    ...mapActions(["postComptabiliteTransaction","leveeFonds","loadBanques","loadTypeTransactions","getListPlanComptes"]),
    ...mapMutations(["setMsgSuccessComptabiliteTransaction","setMsgFailComptabiliteTransaction"]),
    getSousType(){
      this.sousTypeList=""
      this.typeTransactions.forEach(type => {
        console.log(type)
        if (this.form.type == type.type) {
          this.sousTypeList=type.sous_types
        }
      })
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    submit() {
      this.form.date=this.form.date+"-01-01"
      this.budget_mensuel.forEach(element => {
        this.form[element.libelle]=element.valeur
      })
      console.log(this.form.data)
      if (!this.form.validate().errors().any()) {
        this.postComptabiliteTransaction(this.form.data)
      }else{
        this.notif.message = "Veuillez remplir tous les champs requis"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        this.form.date=""

        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
    },
    linearMonth(){
      if (this.opt=='1') {
        for (let index = 0; index < this.budget_mensuel.length; index++) {
          this.budget_mensuel[index].valeur=0.083
        }
      }else{
        for (let index = 0; index < this.budget_mensuel.length; index++) {
          this.budget_mensuel[index].valeur=0
        }
      }
    },
    verifMois(){
      var total=0
      this.budget_mensuel.forEach(mois => {
        total+=Number(mois.valeur)
        console.log(total)
      })
      this.pourcentage=total
      if (total>100) {
        this.notif.message = "Le total des pourcentages ne doit pas dépasser 100%"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    selectCompte(description){
      this.form.libelle=description  
    }
  }  
})
</script>
